import React from "react";
import { Routes, Route } from "react-router-dom";

// Importing components
import { Dashboard } from "../containers/dashboard";
import { Login } from "../containers/auth/login";
import { Logout } from "../containers/auth/logout";
import { Register } from "../containers/auth/register";
import { AuthCallback } from "../containers/auth/callback";
import { Unauthorized } from "../containers/auth/unauthorized";
import { Profile } from "../containers/profile/index";
import { Customers } from "../containers/customers";
import { Users } from "../containers/settings/users";
import { Plan } from "../containers/settings/plan";
import { LocationSettings } from "../containers/locations";
import { LocationSettingsDetails } from "../containers/locations/details";
import { Reporting } from "../containers/reporting";
import { Reports } from "../containers/reporting/reports";
import { Scheduling } from "../containers/scheduling";
import { Organization } from "../containers/settings/organization";
import ProtectedRoute from "./ProtectedRoute";

const RoutesWrapper = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/auth/login/" element={<Login />} />
      <Route path="/auth/logout/" element={<Logout />} />
      <Route path="/auth/callback/" element={<AuthCallback />} />
      <Route path="/auth/register/" element={<Register />} />
      <Route path="/profile/" element={<Profile />} />
      <Route path="/auth/unauthorized/" element={<Unauthorized />} />

      {/* Protected Routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute
            requiredRole="admin:access"
            navigationProps={{ heading: "Dashboard", current: "dashboard" }}
          >
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/scheduling/"
        element={
          <ProtectedRoute
            requiredRole="admin:access"
            navigationProps={{ heading: "Scheduling", current: "scheduling" }}
          >
            <Scheduling />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reporting/activities"
        element={
          <ProtectedRoute
            requiredRole="admin:access"
            navigationProps={{ heading: "Activities", current: "activities" }}
          >
            <Reporting />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reporting/reports/"
        element={
          <ProtectedRoute
            requiredRole="admin:access"
            navigationProps={{ heading: "Reports", current: "reports" }}
          >
            <Reports />
          </ProtectedRoute>
        }
      />
      <Route
        path="/customers/"
        element={
          <ProtectedRoute
            requiredRole="admin:access"
            navigationProps={{ heading: "Customers", current: "customers" }}
          >
            <Customers />
          </ProtectedRoute>
        }
      />

      <Route
        path="/locations/"
        element={
          <ProtectedRoute
            requiredRole="admin:access"
            navigationProps={{ heading: "Locations", current: "locations" }}
          >
            <LocationSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/locations/:id"
        element={
          <ProtectedRoute
            requiredRole="admin:access"
            navigationProps={{ heading: "Locations", current: "locations" }}
          >
            <LocationSettingsDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/users/"
        element={
          <ProtectedRoute
            requiredRole="admin:access"
            navigationProps={{ heading: "Users", current: "users" }}
          >
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/organization/"
        element={
          <ProtectedRoute
            allowInitialization
            requiredRole="admin:access"
            navigationProps={{
              heading: "Organization",
              current: "organization",
            }}
          >
            <Organization />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/plan/"
        element={
          <ProtectedRoute
            requiredRole="admin:access"
            navigationProps={{ heading: "Plan", current: "plan" }}
          >
            <Plan />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/billing/"
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export { RoutesWrapper };
