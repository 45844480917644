import React from "react";
import { Link } from "react-router-dom";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";

function Crumbs(props) {
  return (
    <div className="lg:pl-64">
      <div className="flex pl-6 pt-4">
        <Link to={props.previous} className="hover:underline">
          <ArrowSmallLeftIcon
            className="h-5 w-5 shrink-0 inline"
            aria-hidden="true"
          />
          <span className="pl-2 text-brand-primary">{props.section}</span>
        </Link>
        <span className="px-3">/</span>
        <span className="font-medium">{props.active}</span>
      </div>
    </div>
  );
}

export { Crumbs };
