import { Link } from "react-router-dom";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import logo from "../../assets/logo-mark.svg";
import classNames from "../../utils/classNames";

export default function DesktopNavigation({ navOptions, orgName }) {
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col bg-gray-25 border-gray-100 border-r">
      <div className="flex flex-col h-full">
        <div className="flex items-center pt-7 pl-6 pb-9">
          <Link
            to="/"
            className="font-medium text-gray-600 hover:text-gray-700 hover:bg-gray-50 transition-colors duration-200 group flex items-center mb-1 gap-x-3 p-2 mr-6 rounded-md text-sm leading-6 tracking-wide w-full"
          >
            <BuildingOffice2Icon
              className="h-5 w-5 shrink-0 text-gray-700 hover:text-gray-800 transition-colors duration-200"
              aria-hidden="true"
            />
            <span>{orgName}</span>
          </Link>
        </div>
        <nav className="flex-1">
          <ul role="list" className="flex flex-col">
            {navOptions.map((item) => (
              <li key={item.name} className="flex items-center">
                <Link
                  to={item.href}
                  className={classNames(
                    item.current
                      ? "text-brand-primary font-semibold hover:bg-gray-50"
                      : "font-medium text-gray-600 hover:text-gray-700 hover:bg-gray-50 transition-colors duration-200",
                    "group flex items-center mb-1 gap-x-3 p-2 ml-6 mr-6 rounded-md text-sm leading-6 tracking-wide w-full"
                  )}
                >
                  <item.icon
                    className="h-5 w-5 shrink-0 text-gray-700 hover:text-gray-800 transition-colors duration-200"
                    aria-hidden="true"
                  />
                  <span>{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        {/* Logo section */}
        <div className="flex items-center justify-center py-9">
          <Link to="/">
            <img className="h-8 w-auto" src={logo} alt="Opspot" />
          </Link>
        </div>
      </div>
    </div>
  );
}
