import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { PrimaryButton } from "../../../components/button/primary";
import { LinkButton } from "../../../components/button/link";

function UpdatePaymentForm({ clientSecret, onFinalize, onCancel }) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);
    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: { card: cardElement },
    });

    if (error) {
      console.error("Error confirming card setup:", error);
    } else {
      const paymentMethodId = setupIntent.payment_method;
      await onFinalize(paymentMethodId);
    }
  };

  return (
    <div className="w-1/2 -mt-6">
      <form onSubmit={handleSubmit} className="bg-white">
        <CardElement className="p-2 bg-gray-50 rounded-md" />
        <div className="mt-4 flex w-1/3 pb-6">
          <PrimaryButton type="submit" label="Update" />
          <LinkButton onClick={onCancel} type="function" text="Cancel" />
        </div>
      </form>
    </div>
  );
}

export default UpdatePaymentForm;
