import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { ReportingLocationFilter } from "../filters/locationFilter";
// import { ReportingActivityTypeFilter } from "./filters/activityTypeFilter";
import { ReportingUserFilter } from "../filters/userFilter";
import { ReportingCustomerFilter } from "../filters/customerFilter";
import Datepicker from "react-tailwindcss-datepicker";
import { Table } from "../../../components/table";
import { TableLoadingAnimation } from "../../../components/loading/tableLoading";
import emptyReport from "../../../assets/icons/empty-report.svg";

function Reports() {
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.organization.orgId;

  const [isLoading, setIsLoading] = useState(false);

  /// Customers dropdown ///
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const handleSelectedCustomers = (optionId) => {
    setSelectedCustomers((currentSelected) => {
      if (currentSelected.includes(optionId)) {
        return currentSelected.filter((item) => item !== optionId);
      } else {
        return [...currentSelected, optionId];
      }
    });
  };

  const clearSelectedCustomers = () => {
    setSelectedCustomers([]);
  };

  /// Locations dropdown ///
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locationsOptions, setLocationsOptions] = useState([]);

  const handleSelectedLocations = (optionId) => {
    setSelectedLocations((currentSelected) => {
      if (currentSelected.includes(optionId)) {
        return currentSelected.filter((item) => item !== optionId);
      } else {
        return [...currentSelected, optionId];
      }
    });
  };

  const clearSelectedLocations = () => {
    setSelectedLocations([]);
  };

  /// Actitivity type dropdown ///
  //   const [selectedActivityTypes, setSelectedActivityTypes] = useState([]);

  //   const handleSelectedActivityTypes = (optionId) => {
  //     setSelectedActivityTypes((currentSelected) => {
  //       if (currentSelected.includes(optionId)) {
  //         return currentSelected.filter((item) => item !== optionId);
  //       } else {
  //         return [...currentSelected, optionId];
  //       }
  //     });
  //   };

  //   const clearSelectedActivityTypes = () => {
  //     setSelectedActivityTypes([]);
  //   };

  /// Users dropdown ///
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSelectedUsers = (optionId) => {
    setSelectedUsers((currentSelected) => {
      if (currentSelected.includes(optionId)) {
        return currentSelected.filter((item) => item !== optionId);
      } else {
        return [...currentSelected, optionId];
      }
    });
  };

  const clearSelectedUsers = () => {
    setSelectedUsers([]);
  };

  /// Date picker ///
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });

  const handleselectedDateRange = (selectedDateRange) => {
    console.log("Selected dates:", selectedDateRange);
    setDateRange(selectedDateRange);
    console.log(typeof selectedDateRange.startDate);
  };

  /// Get the data for the table ///
  const constructApiURL = (
    selectedCustomers,
    selectedLocations,
    selectedActivityTypes,
    selectedUsers,
    dateRange
  ) => {
    let baseUrl = `${process.env.REACT_APP_API_URL}/v1/reporting/reports?org_id=${orgId}`;

    if (selectedCustomers.length > 0) {
      // Join the selectedLocations array into a comma-separated string
      const customerIds = selectedCustomers.join(",");
      baseUrl += `&customer_ids=${customerIds}`;
    }

    if (selectedLocations.length > 0) {
      // Join the selectedLocations array into a comma-separated string
      const locationIds = selectedLocations.join(",");
      baseUrl += `&location_ids=${locationIds}`;
    }

    // if (selectedActivityTypes.length > 0) {
    //   // Join the selectedLocations array into a comma-separated string
    //   const activityTypeIds = selectedActivityTypes.join(",");
    //   baseUrl += `&activity_type_ids=${activityTypeIds}`;
    // }

    if (selectedUsers.length > 0) {
      // Join the selectedLocations array into a comma-separated string
      const userIds = selectedUsers.join(",");
      baseUrl += `&user_ids=${userIds}`;
    }

    if (dateRange.startDate) {
      baseUrl += `&start_date=${dateRange.startDate}`;
    }

    // Check if `endDate` is a Date object before using `toISOString`
    if (dateRange.endDate) {
      baseUrl += `&end_date=${dateRange.endDate}`;
    }

    console.log("baseUrl", baseUrl);

    return baseUrl;
  };

  //   const fetchReportsData = async () => {
  //     if (!orgId) return;

  //     const currentApiURL = constructApiURL(
  //       selectedCustomers,
  //       selectedLocations,
  //       //   selectedActivityTypes,
  //       selectedUsers,
  //       dateRange
  //     );

  //     const fetchPromise = fetch(currentApiURL)
  //       .then(async (response) => {
  //         if (response.ok) {
  //           const data = await response.json();
  //           //   setTableData(data);
  //         } else {
  //           // Handle HTTP errors e.g., 404, 500 etc.
  //           console.error("HTTP Error: ", response.statusText);
  //           // Optionally, update state to display an error message
  //         }
  //       })
  //       .catch((error) => {
  //         // Handle network errors, parsing errors, etc.
  //         console.error("Fetching error: ", error.message);
  //         // Optionally, update state to display an error message
  //       });

  //     const timerPromise = new Promise((resolve) => setTimeout(resolve, 1200));

  //     Promise.all([fetchPromise, timerPromise]).then(() => {
  //       setIsLoading(false);
  //     });
  //   };

  //   useEffect(() => {
  //     setIsLoading(true);
  //     fetchReportsData();
  //   }, [
  //     selectedCustomers,
  //     selectedLocations,
  //     // selectedActivityTypes,
  //     selectedUsers,
  //     dateRange,
  //   ]);

  useEffect(() => {
    // Update the selected locations when selected customers change
    setSelectedLocations((currentSelectedLocations) =>
      currentSelectedLocations.filter((location) =>
        locationsOptions.some((option) => option.id === location)
      )
    );
  }, [selectedCustomers, locationsOptions]);

  return (
    <div>
      {/* <Tabs current="Activity" section="reporting" /> */}
      <main className="py-6 lg:pl-64">
        <div className="flex flex-row">
          <div className="w-3/4 h-full px-4 sm:px-6 lg:px-6">Main</div>

          <div className="w-1/4 h-full px-6">
            <div className="flex items-center mb-4 pl-6 bg-gray-25 border-l border-gray-100">
              <div className="flex-1">
                <div className="pl-1 inline mr-4">
                  <ReportingCustomerFilter
                    selected={selectedCustomers}
                    onUpdate={handleSelectedCustomers}
                    onClear={clearSelectedCustomers}
                  />
                </div>
                <div className="pl-1 inline mr-4">
                  <ReportingLocationFilter
                    selected={selectedLocations}
                    onUpdate={handleSelectedLocations}
                    onClear={clearSelectedLocations}
                    customerIds={selectedCustomers}
                    setLocationsOptions={setLocationsOptions}
                  />
                </div>
                {/* <div className="inline mr-4">
                <ReportingActivityTypeFilter
                  selected={selectedActivityTypes}
                  onUpdate={handleSelectedActivityTypes}
                  onClear={clearSelectedActivityTypes}
                />
              </div> */}
                <div className="inline mr-4">
                  <ReportingUserFilter
                    selected={selectedUsers}
                    onUpdate={handleSelectedUsers}
                    onClear={clearSelectedUsers}
                  />
                </div>
                <div className="inline-block">
                  <Datepicker
                    startFrom={new Date("2024-01-05")}
                    showShortcuts={true}
                    inputClassName={
                      "h-9 w-64 inline-flex rounded-md px-3 -ml-1 items-center text-sm leading-none tracking-normal font-normal text-gray-600 placeholder-gray-500 ring-1 ring-gray-100 hover:bg-gray-50 bg-gray-25 border-0 pr-10 focus:outline-none focus:ring-2 focus:ring-gray-100"
                    }
                    separator={"to"}
                    value={dateRange}
                    onChange={handleselectedDateRange}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                {/* <LinkButton
                type="function"
                text="Share"
                onClick={() => {
                  toggleState(setIsDetailsOpen, isDetailsOpen);
                }}
              /> */}
                {/* Add your second button here */}
              </div>
            </div>

            {/* {isLoading ? (
            <TableLoadingAnimation isLoading={isLoading} />
          ) : // Then check if backendData is not empty
          tableData.length > 0 ? (
            <Table data={tableData} columns={columns} />
          ) : (
            <div className="flex flex-col items-center justify-center h-96">
              <img src={emptyReport} className="text-black w-36 h-36" />
              <p className="mt-0 text-gray-300 text-center text-md ">
                No reports for the selected filters
              </p>
            </div>
          )} */}
          </div>
        </div>
      </main>
    </div>
  );
}

export { Reports };
