import React from "react";
import { useTable } from "react-table";

function Table({ data, columns }) {
  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div>
      <table {...getTableProps()} className="min-w-full text-sm">
        <thead className="bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps()}
                  className={`px-3 py-3 text-left text-xxsm font-medium text-black uppercase tracking-wider
                ${index === 0 ? "rounded-tl-lg rounded-bl-lg" : ""} 
                ${
                  index === headerGroup.headers.length - 1
                    ? "rounded-tr-lg rounded-br-lg"
                    : ""
                }`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-y divide-white"
        >
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const cellClassNames = `text-gray-600 px-3 py-2 ${
                    cell.column.nowrap
                      ? "whitespace-nowrap"
                      : "whitespace-normal"
                  }`;

                  return (
                    <td {...cell.getCellProps()} className={cellClassNames}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export { Table };
