import React from "react";
import { Link } from "react-router-dom";

const tabs = {
  settings: [
    { name: "Organization", href: "/settings/organization/", current: true },
    { name: "Users", href: "/settings/users/", current: false },
    { name: "Plan", href: "/settings/plan/", current: false },
    { name: "Billing", href: "/settings/billing/", current: false },
  ],
  reporting: [
    { name: "Activity", href: "/reporting/", current: true },
    { name: "Scheduled", href: "/reporting/schedule/", current: true },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Tabs(props) {
  let section = props.section;

  const updatedTabs = tabs[section].map((tab) => ({
    ...tab,
    current: tab.name === props.current ? true : false,
  }));

  return (
    <div className="lg:pl-64 px-4 sm:px-6 lg:px-8">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-brand-primary focus:outline-none focus:ring-brand-primary sm:text-sm"
          defaultValue={updatedTabs.find((tab) => tab.current).name}
        >
          {updatedTabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block pl-8">
        <div className="">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {updatedTabs.map((tab) => (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  tab.current
                    ? "border-gray-400 text-brand-primary border-t-2 font-semibold"
                    : "border-transparent text-gray-400 border-white border-t-2 hover:text-gray-600 transition-colors duration-200",
                  "whitespace-nowrap py-4 px-8 text-sm font-regular"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

export { Tabs };
