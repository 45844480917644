import React, { useEffect, useState, useContext } from "react";
import Navigation from "../../components/navigation";
import { Tabs } from "../../components/tabs";
import { DeleteConfirm } from "../../components/modal/deleteConfirm";
import { TrashIcon } from "@heroicons/react/24/outline";
import { LinkButton } from "../../components/button/link";
import { PrimaryButton } from "../../components/button/primary";
import { SuccessToast } from "../../components/toast/success";
import { AuthContext } from "../../contexts/AuthContext";
import { CustomerDetails } from "./details";
import { Table } from "../../components/table";
import { TableLoadingAnimation } from "../../components/loading/tableLoading";
import emptyCustomer from "../../assets/icons/empty-customer.svg";

function Customers() {
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.organization.orgId;
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({
    identifier: "",
    id: "",
  });
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const toggleState = (setState, state) => {
    setState(!state);
  };

  const handleDeleteClick = (identifier, id) => {
    setSelectedDeleteItem({ identifier, id });
    toggleState(setIsDeleteOpen, isDeleteOpen);
  };

  const handleAddMode = (id) => {
    setSelectedCustomerId(id);
    setIsAddMode(false);
    toggleState(setIsDetailsOpen, isDetailsOpen);
  };

  /// Delete customer ///
  const deleteCustomer = (customerId) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}/web/admin/customers/delete/${customerId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) => response.json());
  };

  const [deleteSuccess, setDeleteSuccess] = useState({
    displaying: false,
    message: "Customer has been deleted",
  });

  const handleDeleteSuccess = () => {
    fetchCustomers();

    setDeleteSuccess({
      displaying: true,
      message: "Customer has been deleted",
    });
    setTimeout(() => {
      setDeleteSuccess({ displaying: false, message: deleteSuccess.message });
    }, 3000);
  };

  const [fetchFlag, setFetchFlag] = useState(false);

  const handleSuccess = () => {
    setIsLoading(true);
    setFetchFlag(!fetchFlag);
  };

  // Fetch customers when orgId changes
  useEffect(() => {
    if (orgId) {
      setIsLoading(true);
      fetchCustomers();
    }
  }, [orgId]);

  // Fetch customers when fetchFlag changes
  useEffect(() => {
    if (orgId) {
      fetchCustomers();
    }
  }, [fetchFlag]);

  /// Fetch customers ///
  const [backendData, setBackendData] = useState([]);
  const fetchCustomers = async () => {
    if (!orgId) return;

    const fetchPromise = fetch(
      `${process.env.REACT_APP_API_URL}/web/admin/customers?org_id=${orgId}`
    )
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          setBackendData(data); // Update state with the fetched data
        } else {
          // Handle HTTP errors e.g., 404, 500 etc.
          console.error("HTTP Error: ", response.statusText);
          // Optionally, update state to display an error message
        }
      })
      .catch((error) => {
        // Handle network errors, parsing errors, etc.
        console.error("Fetching error: ", error.message);
        // Optionally, update state to display an error message
      });

    const timerPromise = new Promise((resolve) => setTimeout(resolve, 1200));

    Promise.all([fetchPromise, timerPromise]).then(() => {
      setIsLoading(false);
    });
  };

  /// Table columns ///
  const columns = React.useMemo(
    () => [
      {
        Header: "Customer name",
        accessor: "customer_name",
        // Use a custom cell to render the link
        Cell: ({ row }) => (
          <div>
            {row.original.logo_url && row.original.logo_url.length > 0 ? (
              <img
                src={row.original.logo_url}
                alt=""
                className="mr-3 h-10 w-10 p-1 object-contain bg-white inline-block align-middle"
              />
            ) : (
              <span className="mr-2 w-10 h-10 rounded-full bg-gray-200 inline-block align-middle"></span>
            )}
            <a
              onClick={() => handleAddMode(row.original.id)}
              className="text-gray-500 hover:text-gray-600 hover:underline capitalize cursor-pointer"
            >
              {row.values.customer_name}
            </a>
          </div>
        ),
      },
      {
        Header: "Contact name",
        accessor: "contact_name",
      },
      {
        Header: "Contact number",
        accessor: "contact_phone",
      },
      // Add a new column for the delete action
      {
        Header: "",
        id: "delete",
        accessor: (str) => "delete",
        Cell: ({ row }) => (
          <button
            onClick={() =>
              handleDeleteClick(row.original.customer_name, row.original.id)
            }
            className="text-gray-600 hover:text-gray-500"
          >
            <TrashIcon className="h-4 w-4 shrink-0" />
          </button>
        ),
      },
    ],
    []
  );

  /// Toggle side sheet ///
  const toggleDetailsOpen = () => {
    if (isDetailsOpen) {
      setSelectedCustomerId(null);
    }
    setIsDetailsOpen(!isDetailsOpen);
  };

  return (
    <div>
      <main className="py-6 lg:pl-64">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl bold"></h2>
            {
              <div className="justify-end -mr-5">
                <LinkButton
                  type="function"
                  text="Create customer"
                  onClick={() => {
                    setIsAddMode(true);
                    toggleState(setIsDetailsOpen, isDetailsOpen);
                  }}
                />
              </div>
            }
          </div>

          {isLoading ? (
            <TableLoadingAnimation isLoading={isLoading} />
          ) : backendData && backendData.length > 0 ? (
            <Table data={backendData} columns={columns} />
          ) : (
            // If backendData is empty
            <div className="flex flex-col items-center justify-center h-96">
              <img src={emptyCustomer} className="text-black w-36 h-36" />
              <p className="mt-0 text-gray-300 text-center text-md ">
                No customers yet
              </p>
              <LinkButton
                type="function"
                text="Create customer"
                onClick={() => {
                  setIsAddMode(true);
                  toggleState(setIsDetailsOpen, isDetailsOpen);
                }}
              />
            </div>
          )}
        </div>
      </main>
      <DeleteConfirm
        isOpen={isDeleteOpen}
        toggleOpen={() => toggleState(setIsDeleteOpen, isDeleteOpen)}
        selectedDeleteItem={selectedDeleteItem}
        handleDeleteSuccess={handleDeleteSuccess}
        onDelete={deleteCustomer}
      />
      <CustomerDetails
        isOpen={isDetailsOpen}
        toggleOpen={toggleDetailsOpen}
        selectedCustomerId={selectedCustomerId}
        isAddMode={isAddMode}
        onSuccess={handleSuccess}
      />
    </div>
  );
}

export { Customers };
