import React from "react";
import visaIcon from "../../assets/icons/cc-visa.svg";
import mastercardIcon from "../../assets/icons/cc-mastercard.svg";
import amexIcon from "../../assets/icons/cc-amex.svg";

const brandIcons = {
  visa: visaIcon,
  mastercard: mastercardIcon,
  amex: amexIcon,
  // add other brands as needed: discover, diners, jcb, unionpay
};

function PaymentIcon({ brand, last4, expMonth, expYear }) {
  const icon = brandIcons[brand] || null;

  return (
    <div className="flex flex-row items-center space-x-4 text-gray-700">
      {icon && <img src={icon} alt={`${brand} logo`} className="h-8 w-auto" />}
      <span>
        <span className="text-gray-500">**** **** ****</span> {last4}
      </span>
      <span>
        {expMonth}/{expYear}
      </span>
    </div>
  );
}

export default PaymentIcon;
